import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`I was looking for a cleaner and reusable way to construct classes in TypeScript - like it is achieved using named constructors in Dart. I found a way to do this using a bit of help from ChatGPT, generics and the `}<inlineCode parentName="p">{`class-transformer`}</inlineCode>{` library and thought I'd share it here. ChatGPT even co-authors this post with me, as I was too lazy to sum it up myself.`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "ts",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`john`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`name `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`john`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`age `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <p>{`I will walk you through creating a reusable generic base class that allows for object construction in TypeScript, much like you might see in Dart.`}</p>
    <h2>{`Setting the Stage`}</h2>
    <p>{`Let's start with a simple `}<inlineCode parentName="p">{`Human`}</inlineCode>{` class which extends from a `}<inlineCode parentName="p">{`BaseClass`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "typescript",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`extends`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`BaseClass`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`Our goal is to create a new instance of `}<inlineCode parentName="p">{`Human`}</inlineCode>{` like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "typescript",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <p>{`We aim to pass an object during class instantiation, hence creating a constructor with named parameters.`}</p>
    <h2>{`Leveraging class-transformer`}</h2>
    <p>{`One of the libraries that can make this task easier is `}<inlineCode parentName="p">{`class-transformer`}</inlineCode>{`. It offers a neat function, `}<inlineCode parentName="p">{`plainToInstance`}</inlineCode>{`, which can convert plain JavaScript objects to class instances.`}</p>
    <h2>{`Crafting the BaseClass`}</h2>
    <p>{`Let's see how we can construct a `}<inlineCode parentName="p">{`BaseClass`}</inlineCode>{` to achieve this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "typescript",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`plainToInstance`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`class-transformer`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`BaseClass`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`constructor`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`classObject`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Partial`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{})`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Object`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`assign`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`this,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`plainToInstance`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`this.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`constructor`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`any`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`classObject`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`))`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`This base class uses TypeScript's generic types, where `}<inlineCode parentName="p">{`T`}</inlineCode>{` is the type of the object passed to the constructor. It uses `}<inlineCode parentName="p">{`plainToInstance`}</inlineCode>{` to convert this object into an instance of the class, then merges this instance with `}<inlineCode parentName="p">{`this`}</inlineCode>{` using `}<inlineCode parentName="p">{`Object.assign`}</inlineCode>{`.`}</p>
    <p>{`Here's the catch: We're using `}<inlineCode parentName="p">{`this.constructor as any`}</inlineCode>{` to bypass TypeScript's type system. The constructor will indeed be a function at runtime, so this is a safe cast.`}</p>
    <p>{`It would be cool if we didn't have to make the class generic, and instead infer the type from the constructor. Unfortunately AFAIK, TypeScript doesn't allow us to do this. If you have a solution, please let me know in the comments!`}</p>
    <h2>{`Bringing it Together`}</h2>
    <p>{`Now we can define our `}<inlineCode parentName="p">{`Human`}</inlineCode>{` class:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "typescript",
      "data-index": "4"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`extends`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`BaseClass`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`With this setup, TypeScript will check that the object you're passing to the constructor matches `}<inlineCode parentName="p">{`Partial<Human>`}</inlineCode>{`, giving you compile-time checking for the properties. This means if you try to pass an object with properties not declared in `}<inlineCode parentName="p">{`Human`}</inlineCode>{`, TypeScript will give you a type error:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "typescript",
      "data-index": "5"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// This is valid`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`console`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(john)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// This will give a type error`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// const invalidJohn = new Human({ someNonExistingProperty: '123', age: '27' });`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// console.log(invalidJohn);`}</span></span></span></code></pre>
    <h2>{`Wrapping Up`}</h2>
    <p>{`This technique offers a great way to create reusable and extensible base classes in TypeScript. It makes your code type-safe and allows you to leverage TypeScript's static type checking while beautifully creating new class instances.`}</p>
    <p>{`Remember, this technique still depends on `}<inlineCode parentName="p">{`class-transformer`}</inlineCode>{`, and it behaves according to any decorators you've placed on your class properties. If you have complex transformations, you will still need to annotate your properties with the appropriate decorators. But for plain properties like `}<inlineCode parentName="p">{`string`}</inlineCode>{`, `}<inlineCode parentName="p">{`number`}</inlineCode>{`, `}<inlineCode parentName="p">{`boolean`}</inlineCode>{`, and so on, no decorators are needed.`}</p>
    <p>{`Feel free to experiment with this pattern and see how it can make your TypeScript code cleaner and more understandable!`}</p>
    <h2>{`Using a static method instead of generic class`}</h2>
    <p>{`TypeScript does not provide a built-in way to infer the type of `}<inlineCode parentName="p">{`this`}</inlineCode>{` within the class constructor, which is necessary to remove the generic from the base class.`}</p>
    <p>{`This is a complex topic within TypeScript because of the way classes and the `}<inlineCode parentName="p">{`this`}</inlineCode>{` keyword works. When you are in the constructor, the class instance hasn't been fully constructed yet, hence `}<inlineCode parentName="p">{`this`}</inlineCode>{` is not fully typed. The type of `}<inlineCode parentName="p">{`this`}</inlineCode>{` only becomes clear after the constructor has run and the instance has been constructed.`}</p>
    <p>{`One way around this is to move the transformation logic to a static factory method. Here's how:`}</p>
    <pre {...{
      "className": "grvsc-container grvsc-has-line-highlighting material-theme",
      "data-language": "typescript",
      "data-index": "6"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`plainToInstance`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`class-transformer`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`BaseClass`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`static`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`create`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>(this:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` plainObject`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Partial`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>)`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`plainToInstance`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`this,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`plainObject`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`extends`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`BaseClass`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-del"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-del",
            "aria-hidden": "true",
            "data-content": "-"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Human`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// The old way, using 'new Human'`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line grvsc-line-diff grvsc-line-diff-add"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter grvsc-diff-add",
            "aria-hidden": "true",
            "data-content": "+"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` john `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` Human`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`create`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`John`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`age`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`27`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-gutter-pad"
          }}></span><span parentName="span" {...{
            "className": "grvsc-gutter",
            "aria-hidden": "true"
          }}></span><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`console`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(john)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <p>{`In this code, the `}<inlineCode parentName="p">{`create`}</inlineCode>{` method infers the type `}<inlineCode parentName="p">{`T`}</inlineCode>{` from the `}<inlineCode parentName="p">{`this`}</inlineCode>{` value, which is the class constructor when you call `}<inlineCode parentName="p">{`Human.create(...)`}</inlineCode>{`. It returns an instance of the class, created and initialized from the plain object.`}</p>
    <p><em parentName="p">{`Note: that this is not exactly the same as having the transformation logic in the constructor, because you're now calling a static method instead of using the `}<inlineCode parentName="em">{`new`}</inlineCode>{` keyword to create the instance. But it does allow you to create typed instances from plain objects without making `}<inlineCode parentName="em">{`BaseClass`}</inlineCode>{` generic. This solution also does not prevent you from creating a new instance of the class with the `}<inlineCode parentName="em">{`new`}</inlineCode>{` keyword, you just need to make sure to also provide a standard constructor if you wish to allow this.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      