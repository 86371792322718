import { StaticImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';
import MutedText from '../../../../components/MutedText';
import Heading from '../../../../components/Heading';

import {
  Root,
  Author,
  ImageWrapper,
  Image,
  Description,
  AuthorName,
} from './PostFooter.module.css';
import Tags from '../Tags';

const PostFooter = ({ tags }) => (
  <div className={Root}>
    <Tags tags={tags} />
    <div className={Author}>
      <div className={ImageWrapper}>
        <StaticImage
          className={Image}
          src="../../../../assets/images/mattias-fjellvang.jpg"
          alt="Mattias Siø Fjellvang"
          width={150}
        />
      </div>

      <div className={Description}>
        <MutedText>Written by</MutedText>
        <Heading className={AuthorName}>Mattias Siø Fjellvang</Heading>
        <p>
          I’ve been developing websites since I was seven, and has since then been actively involved
          in developing many different applications, primarily for the web. I am very passionate
          about software engineering, and love to build and tinker with tech-stuff.{' '}
          <Link to="/">Read more</Link>
        </p>
      </div>
    </div>
  </div>
);

export default PostFooter;
