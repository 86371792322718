import { Link } from 'gatsby';
import Tag from '../../../../components/Tag';

import { Tag as TagClass } from './Tags.module.css';

const Tags = ({ tags }) => (
  <div>
    {tags.map((tag) => (
      <Tag element={Link} className={TagClass} to={`/tag/${tag}`} key={tag}>
        {tag}
      </Tag>
    ))}
  </div>
);

export default Tags;
