import { MDXProvider } from '@mdx-js/react';
import Container from '../../../../components/Container';
import SEO from '../../../../components/SEO';
import SiteLayout from '../../../../layouts/SiteLayout/SiteLayout';
import CommentSection from '../../components/CommentSection';
import PostFooter from '../../components/PostFooter';

import { ComponentContainer } from './PostLayout.module.css';

const wrapper = (props, Element, isWide = false) => (
  <Container className={ComponentContainer} wide={isWide}>
    <Element {...props} />
  </Container>
);

const PostLayout = ({ pageContext, children }) => (
  <SiteLayout noContainer>
    <SEO title={pageContext.frontmatter.title} description={pageContext.frontmatter.description} />
    <main>
      <Container>
        <h1>{pageContext.frontmatter.title}</h1>
      </Container>
      <MDXProvider
        components={{
          p: (props) => wrapper(props, 'p'),
          ul: (props) => wrapper(props, 'ul'),
          ol: (props) => wrapper(props, 'ol'),
          h2: (props) => wrapper(props, 'h2'),
          h3: (props) => wrapper(props, 'h3'),
          h4: (props) => wrapper(props, 'h4'),
          h5: (props) => wrapper(props, 'h5'),
          pre: (props) => wrapper(props, 'pre', true),

          img: (props) => wrapper(props, 'img', true),
        }}
      >
        {children}
      </MDXProvider>
    </main>
    <Container>
      <PostFooter {...pageContext.frontmatter} />
      <CommentSection />
    </Container>
  </SiteLayout>
);

export default PostLayout;
