const CommentSection = () => (
  <>
    <h2>Comments</h2>
    <div
      ref={(element) => {
        if (!element) return;

        const script = document.createElement('script');
        script.setAttribute('src', 'https://utteranc.es/client.js');
        script.setAttribute('crossorigin', 'anonymous');
        script.setAttribute('async', true);
        script.setAttribute('repo', 'constantsolutions/website-comments');
        script.setAttribute('issue-term', 'pathname');
        script.setAttribute('theme', 'preferred-color-scheme'); // preferred-color-scheme
        element.replaceChildren(script);
      }}
    />
  </>
);

export default CommentSection;
