import classNames from 'classnames';
import MutedText from '../MutedText';

import { Root } from './Tag.module.css';

const Tag = ({ element: Element, className, ...props }) => (
  <Element className={classNames(Root, className)} {...props} />
);

Tag.defaultProps = {
  element: MutedText,
};

export default Tag;
